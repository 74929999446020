// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-plasmic-components-pages-cart-jsx": () => import("./../../.plasmic/components/pages/cart.jsx" /* webpackChunkName: "component---cache-plasmic-components-pages-cart-jsx" */),
  "component---cache-plasmic-components-pages-category-jsx": () => import("./../../.plasmic/components/pages/category.jsx" /* webpackChunkName: "component---cache-plasmic-components-pages-category-jsx" */),
  "component---cache-plasmic-components-pages-index-jsx": () => import("./../../.plasmic/components/pages/index.jsx" /* webpackChunkName: "component---cache-plasmic-components-pages-index-jsx" */),
  "component---cache-plasmic-components-pages-item-jsx": () => import("./../../.plasmic/components/pages/item.jsx" /* webpackChunkName: "component---cache-plasmic-components-pages-item-jsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

