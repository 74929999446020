// extracted by mini-css-extract-plugin
export var root = "PlasmicCopyrightSocialLanguage-module--root--id093";
export var freeBox = "PlasmicCopyrightSocialLanguage-module--freeBox--2vy1L";
export var freeBox__w1X5M = "PlasmicCopyrightSocialLanguage-module--freeBox__w1X5M--3z_i4";
export var freeBox__hi6Gm = "PlasmicCopyrightSocialLanguage-module--freeBox__hi6Gm--2e66x";
export var freeBox__ds5LO = "PlasmicCopyrightSocialLanguage-module--freeBox__ds5LO--1skBn";
export var svg__qwrd4 = "PlasmicCopyrightSocialLanguage-module--svg__qwrd4--15_qc";
export var svg__hgJuu = "PlasmicCopyrightSocialLanguage-module--svg__hgJuu--dz21d";
export var svg__aqe4A = "PlasmicCopyrightSocialLanguage-module--svg__aqe4A--1zj1D";
export var svg__aPCkN = "PlasmicCopyrightSocialLanguage-module--svg__aPCkN--27bP_";
export var svg__vRcf6 = "PlasmicCopyrightSocialLanguage-module--svg__vRcf6--rBIQO";
export var svg__wsnvv = "PlasmicCopyrightSocialLanguage-module--svg__wsnvv--3IjSZ";
export var button__rJedQ = "PlasmicCopyrightSocialLanguage-module--button__rJedQ--302_1";
export var svg__deBnl = "PlasmicCopyrightSocialLanguage-module--svg__deBnl--2m2RU";
export var svg__d2KRe = "PlasmicCopyrightSocialLanguage-module--svg__d2KRe--374KF";
export var svg__y2QB = "PlasmicCopyrightSocialLanguage-module--svg__y2QB--2Hbxu";
export var svg__sxRdl = "PlasmicCopyrightSocialLanguage-module--svg__sxRdl--3G9v4";
export var svg__jt8Yz = "PlasmicCopyrightSocialLanguage-module--svg__jt8Yz--Qo3N-";
export var svg___9Oq6J = "PlasmicCopyrightSocialLanguage-module--svg___9Oq6J--2KvwR";
export var button__kkEv0 = "PlasmicCopyrightSocialLanguage-module--button__kkEv0--3VPBS";
export var svg__giPn2 = "PlasmicCopyrightSocialLanguage-module--svg__giPn2--ppwmD";
export var svg__nUplC = "PlasmicCopyrightSocialLanguage-module--svg__nUplC--3S11g";
export var svg__jjkZ = "PlasmicCopyrightSocialLanguage-module--svg__jjkZ--EDWiW";
export var svg__rVJqR = "PlasmicCopyrightSocialLanguage-module--svg__rVJqR--3Cpll";
export var svg__f7Wds = "PlasmicCopyrightSocialLanguage-module--svg__f7Wds--2k5cc";
export var svg__saabp = "PlasmicCopyrightSocialLanguage-module--svg__saabp--1jYX_";
export var button___8WBjA = "PlasmicCopyrightSocialLanguage-module--button___8WBjA--3kDV2";
export var svg__ml2DZ = "PlasmicCopyrightSocialLanguage-module--svg__ml2DZ--3FdJP";
export var svg__eu1Em = "PlasmicCopyrightSocialLanguage-module--svg__eu1Em--3Q4W_";
export var svg__eTVs2 = "PlasmicCopyrightSocialLanguage-module--svg__eTVs2--1FkQ5";
export var svg__doZRt = "PlasmicCopyrightSocialLanguage-module--svg__doZRt--VWrOR";
export var svg___1HyIo = "PlasmicCopyrightSocialLanguage-module--svg___1HyIo--2rPU7";
export var svg__fj0O = "PlasmicCopyrightSocialLanguage-module--svg__fj0O--1UKQH";