// extracted by mini-css-extract-plugin
export var root = "PlasmicValue-module--root--3QIFj";
export var root__topBottom = "PlasmicValue-module--root__topBottom--Y2aHe";
export var root__center = "PlasmicValue-module--root__center--30OXX";
export var freeBox__xdsoO = "PlasmicValue-module--freeBox__xdsoO--33OAw";
export var freeBox__topBottom__xdsoOvC7GJ = "PlasmicValue-module--freeBox__topBottom__xdsoOvC7GJ--3wY2k";
export var freeBox__vlY5S = "PlasmicValue-module--freeBox__vlY5S--3njAY";
export var svg__smGnw = "PlasmicValue-module--svg__smGnw--1XTGw";
export var freeBox__ragxD = "PlasmicValue-module--freeBox__ragxD--3_Y9G";
export var freeBox__center__ragxDCpUHn = "PlasmicValue-module--freeBox__center__ragxDCpUHn--2m8oM";
export var slotChildren__center = "PlasmicValue-module--slotChildren__center--34cXH";
export var freeBox__tCHdC = "PlasmicValue-module--freeBox__tCHdC--1rRJn";
export var freeBox__kC8DF = "PlasmicValue-module--freeBox__kC8DF--1P91k";
export var freeBox__nLib6 = "PlasmicValue-module--freeBox__nLib6--2jMHq";
export var freeBox__center__nLib6CpUHn = "PlasmicValue-module--freeBox__center__nLib6CpUHn--2dM0Q";
export var slotChildren2__center = "PlasmicValue-module--slotChildren2__center--vh6tM";
export var button___8Gy31 = "PlasmicValue-module--button___8Gy31--NHZS9";
export var svg__xKsCa = "PlasmicValue-module--svg__xKsCa--3lxSg";
export var svg__rSqC = "PlasmicValue-module--svg__rSqC--zg5TS";
export var svg__fksOw = "PlasmicValue-module--svg__fksOw--1_RtD";
export var svg__fPjOr = "PlasmicValue-module--svg__fPjOr--MQx3n";
export var svg__rNc06 = "PlasmicValue-module--svg__rNc06--2O8rY";
export var svg___0JzH2 = "PlasmicValue-module--svg___0JzH2--1LIu3";