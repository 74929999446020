// extracted by mini-css-extract-plugin
export var root = "PlasmicProductCard-module--root--3QoMB";
export var link = "PlasmicProductCard-module--link--101Hg";
export var link__small = "PlasmicProductCard-module--link__small--2hIcm";
export var img__jb1F3 = "PlasmicProductCard-module--img__jb1F3--3hJUv";
export var freeBox__wayiV = "PlasmicProductCard-module--freeBox__wayiV--fY5-V";
export var root__small = "PlasmicProductCard-module--root__small--24o4u";
export var freeBox__small__wayiV4WviD = "PlasmicProductCard-module--freeBox__small__wayiV4WviD--2rdPU";
export var freeBox__bXrnu = "PlasmicProductCard-module--freeBox__bXrnu--17FBP";
export var freeBox__small__bXrnu4WviD = "PlasmicProductCard-module--freeBox__small__bXrnu4WviD--3pnYd";
export var freeBox__idIEd = "PlasmicProductCard-module--freeBox__idIEd--VS04B";
export var svg___79Gj = "PlasmicProductCard-module--svg___79Gj--2eLNU";
export var svg__f9H7P = "PlasmicProductCard-module--svg__f9H7P--3zqOU";
export var svg__s43Sq = "PlasmicProductCard-module--svg__s43Sq--1Mt9L";
export var svg__ee3VG = "PlasmicProductCard-module--svg__ee3VG--3Qu7x";
export var svg__zA6T = "PlasmicProductCard-module--svg__zA6T--UNzvq";
export var svg__nqyi = "PlasmicProductCard-module--svg__nqyi--oI8dd";
export var svg__txcl2 = "PlasmicProductCard-module--svg__txcl2--19uIs";
export var svg__sdsAs = "PlasmicProductCard-module--svg__sdsAs--3opFH";
export var svg__gj4Pk = "PlasmicProductCard-module--svg__gj4Pk--3A3ZK";
export var svg__rpDwo = "PlasmicProductCard-module--svg__rpDwo--2Wdsi";
export var svg___6N3T = "PlasmicProductCard-module--svg___6N3T--PYs6J";
export var svg__kVcKs = "PlasmicProductCard-module--svg__kVcKs--R1mNb";
export var freeBox__cgUQ = "PlasmicProductCard-module--freeBox__cgUQ--1jcjy";
export var freeBox__bQNsN = "PlasmicProductCard-module--freeBox__bQNsN--3QHAz";
export var freeBox__bcPAe = "PlasmicProductCard-module--freeBox__bcPAe--3Qm8l";
export var price = "PlasmicProductCard-module--price--Nu05E";
export var freeBox__r5RZj = "PlasmicProductCard-module--freeBox__r5RZj--regb-";
export var freeBox__tZo06 = "PlasmicProductCard-module--freeBox__tZo06--3guq0";
export var freeBox___9R1O = "PlasmicProductCard-module--freeBox___9R1O--Y2Esr";