// extracted by mini-css-extract-plugin
export var root = "PlasmicEmojiValueProps-module--root--1m3RB";
export var freeBox__n813W = "PlasmicEmojiValueProps-module--freeBox__n813W--VYniJ";
export var value__okral = "PlasmicEmojiValueProps-module--value__okral--2vR-7";
export var freeBox__lva6G = "PlasmicEmojiValueProps-module--freeBox__lva6G--2xwjO";
export var freeBox__ny8KR = "PlasmicEmojiValueProps-module--freeBox__ny8KR--1mjPf";
export var svg__dR87 = "PlasmicEmojiValueProps-module--svg__dR87--C6GR7";
export var button__jRk40 = "PlasmicEmojiValueProps-module--button__jRk40--3mROw";
export var svg__h5N8M = "PlasmicEmojiValueProps-module--svg__h5N8M--1Cp60";
export var svg__mout1 = "PlasmicEmojiValueProps-module--svg__mout1--16NRB";
export var svg__p13W = "PlasmicEmojiValueProps-module--svg__p13W--1czL2";
export var svg__o3NMu = "PlasmicEmojiValueProps-module--svg__o3NMu--2agHc";
export var svg__jtYsv = "PlasmicEmojiValueProps-module--svg__jtYsv--3oTMa";
export var svg__acaDi = "PlasmicEmojiValueProps-module--svg__acaDi--eXw60";
export var value__j9U3H = "PlasmicEmojiValueProps-module--value__j9U3H--27Bub";
export var freeBox__wFaO5 = "PlasmicEmojiValueProps-module--freeBox__wFaO5--3Z5uQ";
export var freeBox___8CanA = "PlasmicEmojiValueProps-module--freeBox___8CanA--dbslt";
export var svg__kUdJz = "PlasmicEmojiValueProps-module--svg__kUdJz--3GfjS";
export var button__j4DUf = "PlasmicEmojiValueProps-module--button__j4DUf--1eQp2";
export var svg__j4HPc = "PlasmicEmojiValueProps-module--svg__j4HPc--1GAEC";
export var svg__vBhN7 = "PlasmicEmojiValueProps-module--svg__vBhN7--1FBs_";
export var svg__ka0M = "PlasmicEmojiValueProps-module--svg__ka0M--3ksVb";
export var svg__u7Bhd = "PlasmicEmojiValueProps-module--svg__u7Bhd--1Hb9x";
export var svg__uEdGb = "PlasmicEmojiValueProps-module--svg__uEdGb--LgjlD";
export var svg__l7Jet = "PlasmicEmojiValueProps-module--svg__l7Jet--2kM5I";
export var value__eyDay = "PlasmicEmojiValueProps-module--value__eyDay--1En8-";
export var freeBox___16EeD = "PlasmicEmojiValueProps-module--freeBox___16EeD--2Iel5";
export var freeBox__feIEe = "PlasmicEmojiValueProps-module--freeBox__feIEe--2wCs-";
export var svg___3NUcb = "PlasmicEmojiValueProps-module--svg___3NUcb--2EHR5";
export var button__avRf = "PlasmicEmojiValueProps-module--button__avRf--1NAGu";
export var svg__eZhEx = "PlasmicEmojiValueProps-module--svg__eZhEx--3jSiT";
export var svg__zfw9E = "PlasmicEmojiValueProps-module--svg__zfw9E--3uNmX";
export var svg__gefN9 = "PlasmicEmojiValueProps-module--svg__gefN9--ffmDB";
export var svg__yo1Ns = "PlasmicEmojiValueProps-module--svg__yo1Ns--12FRx";
export var svg__hvFal = "PlasmicEmojiValueProps-module--svg__hvFal--1O8GC";
export var svg__joJxr = "PlasmicEmojiValueProps-module--svg__joJxr--1NYst";