// extracted by mini-css-extract-plugin
export var root = "PlasmicCartItem-module--root--1hO1n";
export var img = "PlasmicCartItem-module--img--2C5IS";
export var freeBox = "PlasmicCartItem-module--freeBox--1VCaz";
export var link__sAzVd = "PlasmicCartItem-module--link__sAzVd--1a7pp";
export var freeBox__lGctS = "PlasmicCartItem-module--freeBox__lGctS--ENlM4";
export var freeBox__i27Mw = "PlasmicCartItem-module--freeBox__i27Mw--18NuQ";
export var freeBox__fgBoj = "PlasmicCartItem-module--freeBox__fgBoj--2rdsb";
export var freeBox___9FBhK = "PlasmicCartItem-module--freeBox___9FBhK--3qjv5";
export var svg__zRtSs = "PlasmicCartItem-module--svg__zRtSs--UHFy3";
export var svg__a9NAt = "PlasmicCartItem-module--svg__a9NAt--1nzs6";