// extracted by mini-css-extract-plugin
export var root = "PlasmicButton-module--root--1SlQK";
export var root__colors_lightGray = "PlasmicButton-module--root__colors_lightGray--Ezhbg";
export var root__colors_lightGrayOutline = "PlasmicButton-module--root__colors_lightGrayOutline--38Yk5";
export var root__size_large = "PlasmicButton-module--root__size_large--1uL0L";
export var root__size_small = "PlasmicButton-module--root__size_small--i6Dyx";
export var root__size_narrow = "PlasmicButton-module--root__size_narrow--1WXDM";
export var root__rounded = "PlasmicButton-module--root__rounded--1c3X9";
export var root__link = "PlasmicButton-module--root__link--1SCxU";
export var root__states_active = "PlasmicButton-module--root__states_active--dv7Jl";
export var root__icons_five = "PlasmicButton-module--root__icons_five--wix3m";
export var root__colors_gray = "PlasmicButton-module--root__colors_gray--ASH8N";
export var root__colors_white = "PlasmicButton-module--root__colors_white--3YoQQ";
export var root__colors_blue = "PlasmicButton-module--root__colors_blue--3034D";
export var root__colors_black = "PlasmicButton-module--root__colors_black--1Vz1x";
export var root__rounded_size_large = "PlasmicButton-module--root__rounded_size_large--120xt";
export var freeBox__twLlw = "PlasmicButton-module--freeBox__twLlw--2NPyu";
export var freeBox__size_large__twLlwftEa0 = "PlasmicButton-module--freeBox__size_large__twLlwftEa0--3MaCW";
export var freeBox__size_small__twLlWpwtNq = "PlasmicButton-module--freeBox__size_small__twLlWpwtNq--2_5I1";
export var slotChildren__colors_gray = "PlasmicButton-module--slotChildren__colors_gray--9IhFW";
export var slotChildren__colors_blue = "PlasmicButton-module--slotChildren__colors_blue--21KbN";
export var slotChildren__colors_black = "PlasmicButton-module--slotChildren__colors_black--nH1Ml";
export var svg__orrWk = "PlasmicButton-module--svg__orrWk--31HkE";
export var freeBox__qBkr8 = "PlasmicButton-module--freeBox__qBkr8--30OJu";
export var freeBox__size_large__qBkr8FtEa0 = "PlasmicButton-module--freeBox__size_large__qBkr8FtEa0--eazYG";
export var freeBox__size_small__qBkr8PwtNq = "PlasmicButton-module--freeBox__size_small__qBkr8PwtNq--guOhf";
export var slotChildren3__colors_gray = "PlasmicButton-module--slotChildren3__colors_gray--3WjA7";
export var slotChildren3__colors_blue = "PlasmicButton-module--slotChildren3__colors_blue--1QLb2";
export var slotChildren3__colors_black = "PlasmicButton-module--slotChildren3__colors_black--1fTh0";
export var svg__n33IB = "PlasmicButton-module--svg__n33IB--PFjCX";
export var freeBox__iR1YZ = "PlasmicButton-module--freeBox__iR1YZ--2V3Eh";
export var freeBox__size_large__iR1YZftEa0 = "PlasmicButton-module--freeBox__size_large__iR1YZftEa0--2BzRE";
export var freeBox__size_small__iR1YZpwtNq = "PlasmicButton-module--freeBox__size_small__iR1YZpwtNq--26WDk";
export var slotChildren32__colors_gray = "PlasmicButton-module--slotChildren32__colors_gray--1fo80";
export var slotChildren32__colors_blue = "PlasmicButton-module--slotChildren32__colors_blue--1UuQr";
export var slotChildren32__colors_black = "PlasmicButton-module--slotChildren32__colors_black--3JUnZ";
export var svg__x2YaS = "PlasmicButton-module--svg__x2YaS--3OJ7F";
export var freeBox__jwJae = "PlasmicButton-module--freeBox__jwJae--3F45R";
export var freeBox__size_large__jwJaeFtEa0 = "PlasmicButton-module--freeBox__size_large__jwJaeFtEa0--1POVV";
export var freeBox__size_small__jwJaepwtNq = "PlasmicButton-module--freeBox__size_small__jwJaepwtNq--2Sxuj";
export var slotChildren322__colors_gray = "PlasmicButton-module--slotChildren322__colors_gray--J-PCb";
export var slotChildren322__colors_blue = "PlasmicButton-module--slotChildren322__colors_blue--tCM7y";
export var slotChildren322__colors_black = "PlasmicButton-module--slotChildren322__colors_black--N2Tky";
export var svg__u9HvW = "PlasmicButton-module--svg__u9HvW--2-G7W";
export var freeBox__hxGLm = "PlasmicButton-module--freeBox__hxGLm--1e1fn";
export var freeBox__size_large__hxGLmFtEa0 = "PlasmicButton-module--freeBox__size_large__hxGLmFtEa0--L5Ub8";
export var freeBox__size_small__hxGLmpwtNq = "PlasmicButton-module--freeBox__size_small__hxGLmpwtNq--2VfD1";
export var slotChildren3222__colors_gray = "PlasmicButton-module--slotChildren3222__colors_gray--3cqDG";
export var slotChildren3222__colors_blue = "PlasmicButton-module--slotChildren3222__colors_blue--10nCe";
export var slotChildren3222__colors_black = "PlasmicButton-module--slotChildren3222__colors_black--4PpAK";
export var svg__nuKhr = "PlasmicButton-module--svg__nuKhr--1fHsI";
export var slotSlot = "PlasmicButton-module--slotSlot--1yHUd";
export var slotSlot__size_large = "PlasmicButton-module--slotSlot__size_large--2fupN";
export var slotSlot__size_small = "PlasmicButton-module--slotSlot__size_small--3WNeX";
export var slotSlot__link = "PlasmicButton-module--slotSlot__link--2EM-Y";
export var slotSlot__colors_gray = "PlasmicButton-module--slotSlot__colors_gray--zY_1Y";
export var slotSlot__colors_blue = "PlasmicButton-module--slotSlot__colors_blue--2R_Hd";
export var slotSlot__colors_black = "PlasmicButton-module--slotSlot__colors_black--1XT09";
export var __wab_slot = "PlasmicButton-module--__wab_slot--3_D-M";
export var freeBox___5Ci7F = "PlasmicButton-module--freeBox___5Ci7F--29p6P";
export var freeBox__icons_end___5Ci7FbBesm = "PlasmicButton-module--freeBox__icons_end___5Ci7FbBesm--22sgR";
export var slotChildren2__link = "PlasmicButton-module--slotChildren2__link--2tz45";
export var slotChildren2__colors_black = "PlasmicButton-module--slotChildren2__colors_black--38_8K";
export var svg__lY8Y6 = "PlasmicButton-module--svg__lY8Y6--2yMQ1";