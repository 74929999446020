// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react"
import { classNames } from "@plasmicapp/react-web"

export function Icon18Icon(props) {
  const { className, style, title, ...restProps } = props
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 36 36"}
      height={"1em"}
      width={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M24.161 10.166l-.676-2.034a.564.564 0 00-.781-.275c-.918.487-2.944 1.318-3.684 1.575 1.419.505 3.499 1.503 4.511 1.396.384-.04.787-.308.63-.662zm10.21 24.574c-.399.225-6.509 1.692-18.621-8.906-3.667-3.21-14.55-12.955-14.409-13.287 0 0-.329.36-.602.736-.197.27-.319.559-.274.848.31 1.967 3.112 3.819 6.962 6.78l.549.423.363.279c.588.452 2.316 1.815 4.329 3.403 2.753 2.17 8.284 6.49 8.445 6.61 2.12 1.574 4.635 2.817 7.667 3.672 3.838 1.08 5.591-.558 5.591-.558z"
        }
        fill={"#8899A6"}
      ></path>

      <path
        d={
          "M34.371 34.74s.477-.219.97-.918c.493-.699.79-1.924.045-3.248-.745-1.323-3.456-5.121-4.345-6.671-.433-.755-.297-1.447-1.125-2.835 0 0-3.164-5.523-3.864-6.723-1.497-2.567-.84-3.902-1.837-4.668-.665-.511-7.306.166-9.327-1.389-1.397-1.074-1.418-4.428-2.003-5.526-.466-.875-2.02-.475-3.087.11-1.067.585-3.973 3.789-4.675 4.702-.702.913-1.601 1.909-1.679 2.928l-.079 1.018S19.226 26.67 23.74 29.726c7.697 5.211 10.631 5.014 10.631 5.014z"
        }
        fill={"#BE1931"}
      ></path>

      <path
        d={
          "M24.74 10.464c-.103-.314-.249-.575-.525-.788-.665-.511-7.306.167-9.327-1.389-1.397-1.074-1.418-4.429-2.003-5.526-.182-.341-.532-.485-.952-.502.69 1.4.621 5.574 2.312 6.874 2.038 1.569 8.016 1.133 10.495 1.331z"
        }
        fill={"#A0041E"}
      ></path>

      <path
        d={
          "M13.265 17.873c.505-1.472 1.044-4.915.074-6.962-.909-1.917-4.441-5.955-5.112-6.72C6.987 5.296 5.33 7.229 4.83 7.879c-.66.858-1.455 1.822-1.494 2.8-.014.353.087.51.087.51s9.822 6.742 9.842 6.684z"
        }
        fill={"#DD2E44"}
      ></path>

      <path
        d={
          "M23.107 14.256a.838.838 0 01-.306-1.62l1.823-.715a.84.84 0 01.613 1.562l-1.823.715a.848.848 0 01-.307.058zm1.347 3.063a.838.838 0 01-.419-1.566l1.793-1.03a.839.839 0 01.836 1.453l-1.793 1.031a.832.832 0 01-.417.112zm1.821 2.752a.838.838 0 01-.502-1.511l1.514-1.129a.84.84 0 011.002 1.345l-1.514 1.128a.828.828 0 01-.5.167zm1.945 2.571a.84.84 0 01-.532-1.488l1.155-.948a.84.84 0 011.065 1.297l-1.155.948a.842.842 0 01-.533.191zM3.2 10.76s.675.612 1.425.726c.75.114 2.079.95 2.993 1.653.914.703 4.399 3.292 6.534 5.444s5.495 6.58 6.801 7.863c1.306 1.282 3.413 3.193 5.214 4.347 1.801 1.154 3.455 2.131 4.516 2.686 1.368.716 2.632 1.144 3.688 1.261-.368.216-2.313.946-5.69-.205-3.228-1.1-5.332-2.294-7.071-3.586-1.739-1.292-11.208-8.808-12.759-10-1.551-1.194-6.188-4.729-6.92-5.788-1.04-1.504-.99-2.162-.788-2.424.492-.64 1.531-2.382 2.057-1.977z"
        }
        fill={"#CCD6DD"}
      ></path>

      <path
        d={
          "M35.386 30.574c-.745-1.323-3.456-5.121-4.345-6.671-.174-.304-.257-.6-.347-.931-.091.034-.189.054-.269.109-1.154.792-1.148 3.185.571 5.687 1.378 2.006 3.59 3.552 4.832 3.576.026-.538-.088-1.142-.442-1.77z"
        }
        fill={"#DD2E44"}
      ></path>
    </svg>
  )
}

export default Icon18Icon
/* prettier-ignore-end */
